import React, { Component } from 'react'
import classNames from 'classnames';

require('./styles.scss');

const contentEditable = (WrappedComponent) => {
  return class extends Component {
    state = {
      editing: false,
      isEmpty: true,
    }

    componentDidMount() {
      if (this.props.value !== this.props.placeholder) {
        this.setState({ isEmpty: false });
      }
    } 

    toggleEdit = (e) => {
      e.stopPropagation();
      if (this.state.isEmpty) {
        this.domElm.textContent = "";
      }
      if (this.state.editing) {
        return this.cancel();
      }
      this.edit();
    }

    edit = () => {
      this.setState({
        editing: true,
      }, () => {
        this.domElm.focus();
      });
    }

    save = () => {
      let newText = this.domElm.textContent;
      if (newText === '' || !newText) {
        this.domElm.textContent = this.props.placeholder;
        this.setState({ isEmpty: true });
      } else {
        this.setState({ isEmpty: false });
      }
      this.setState({
        editing: false,
      }, () => {
        if (this.props.onSave && this.isValueChanged()) {
          this.props.onSave(this.domElm.textContent);
        }
      });
    }

    cancel = () => {
      this.setState({
        editing: false,
      });
    }

    isValueChanged = () => {
      return this.props.value !== this.domElm.textContent;
    }

    handleKeyDown = (e) => {
      const { key } = e;
      this.setState({ isEmpty: false });
      switch (key) {
        case 'Enter':
        case 'Escape':
          this.save();
          break;
        
        default:
          break;
        }
      }

      render() {
        let editOnClick = true;
        let newText;
        if (this.domElm) {
          newText = this.domElm.textContent;
        }

        const { editing } = this.state;
        if (this.props.editOnClick !== undefined) {
          editOnClick = this.props.editOnClick;
        }

        const classes = classNames(
          'm-0',
          'p-0',
          'bg-transparent',
          {
            'editing': editing,
            'is-empty': this.state.isEmpty,
          }
        );

        return (
          <WrappedComponent
            className={classes}
            onClick={editOnClick ? this.toggleEdit : undefined}
            contentEditable={editing}
            ref={(domNode) => {
              this.domElm = domNode;
            }}
            onBlur={this.save}
            onKeyDown={this.handleKeyDown}
            {...this.props}
          >
            {this.props.value}
          </WrappedComponent>
        );
      }
  }
}

export default contentEditable;
