import React, { useEffect, useState } from 'react';
import {Link, withRouter} from 'react-router-dom';
import ProjectService from '../../services/project.service';

import { useAuth0 } from '../Auth';
import DashboardGridItem from '../DashboardGridItem';
import {prepFilesForUpload} from '../../util/assets';
import Upload from '../Upload';

require('./styles.scss');

const logo = require('../../img/logo.png');
const uuidv4 = require('uuid/v4');

const claimNamespace = 'https://reviewcycle.io';

const Dashboard = (props) => {
  const { claims, getTokenSilently, user, logout } = useAuth0();
  const [ projects, setProjects ] = useState([]);
  const [ error, setError ] = useState('');
  const [ uploadActive, setUploadActive ] = useState(false);
  const [ uploading, setUploading ] = useState(false);

  const getProjects = async () => {
    const token = await getTokenSilently();
    ProjectService.getProjects(token)
      .then((projects) => {
        setProjects(projects);
      })
      .catch((err) => {
        console.log('ERROR', err);
        setError(err);
      });
  };

  const afterDropFiles = (files) => {
    setUploading(true);
    if (window.ga) {
      window.ga('send', 'event', 'upload', 'click');
    }
    const slug = uuidv4().substr(0,6);

    const payload = {
      owner: user.sub,
      slug,
    }; 

    ProjectService.createProject(payload)
      .then((project) => {
        prepFilesForUpload(files)
          .then((data) => {
            ProjectService.uploadAssets(project._id, data)
              .then((response) => { 
                props.history.replace(`/p/${project.slug}`);
              })
              .catch((e) => {
                console.log('Error', e)
              });
            })
            .catch((error) => {
              setError(error);
              setUploading(false); 
            })
          }) 
      .catch((err) => console.log(err));
  }

  const logOut = () => {
    logout({
      returnTo: window.location.origin,
    });
  }

  useEffect(() => { 
    getProjects();
  }, []);

  let freeTierLimit = 5;

  if (user) {
    const storedFreeTierLimit = user[`${claimNamespace}/app_metadata`].freeTierLimit;
    if (storedFreeTierLimit) {
      freeTierLimit = storedFreeTierLimit;
    }
  }

  const hasReachedFreeTierLimit = projects.length >= freeTierLimit;

  return (
    <div>
      <nav className="navbar d-flex align-items-center">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="ReviewCycle" />
        </Link> 
          {!uploadActive && !hasReachedFreeTierLimit &&
          <div className="d-flex align-items-center mr-3 ml-auto cta rounded"> 
            <button className="btn btn-primary" onClick={() => setUploadActive(true)}>Create a new project</button>
          </div>
        }
        {uploadActive &&
          <div className="d-flex align-items-center mr-3 ml-auto cta rounded"> 
            <button className="btn btn-danger" onClick={() => setUploadActive(false)}>Cancel</button>
          </div>
        }
        <button className="btn btn-outline-primary" onClick={logOut}>Log out</button>
      </nav>
      {!uploadActive &&
        <div className="pt-4 px-3">
          <h6 className="mb-4">Your Projects</h6>
            {hasReachedFreeTierLimit &&
              <div className="upgrade-banner p-3 rounded mb-4">
                <h5>Looks like you're loving ReviewCycle!</h5>
                <p className="mb-0">
                  You've run out of free projects ({freeTierLimit}). Hit us on chat, or email 
                  us at <a href="mailto:sales@reviewcycle.io">sales@reviewcycle.io</a> 
                  {' '}to upgrade to unlimited projects for $29/month.
                </p>
              </div>
            }
            {projects.map((project) => (
              <DashboardGridItem 
                project={project}
                key={project._id}
              />
            ))}
        </div> 
      }
      {uploadActive &&
        <div className="m-5 p-5 text-center w-50 mx-auto">
          <h3 className="mb-3">Upload a file to create a new project</h3>
          <Upload 
            afterDrop={afterDropFiles}
            loading={uploading}
            fullScreen 
            prompt='Drag a file here or click to upload'
          />
        </div>
      }
    </div>
  );
}


export default withRouter(Dashboard);

