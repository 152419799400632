import React from 'react';
import classNames from 'classnames';
import moment from 'moment';

require('./styles.scss');

const AssetPreview = props => {
  const onClick = () => {
    props.onClick(props.asset);
  }

  const assetPreviewClasses = classNames(
    'asset-preview',  
    'rounded',
    'mb-5',
    'position-relative',
    {
      'selected': props.selected,
    }
  );

  const currentVersion = props.asset.files[props.asset.files.length - 1];
  const title = props.asset.title || currentVersion.file.originalname;

  return (
    <div className={assetPreviewClasses} onClick={onClick}> 
      {props.selected &&
        <div className="selected-indicator rounded-lg px-3 d-flex align-items-center text-uppercase">Selected Asset</div>  
      }
      <div className="asset-preview-image">
        <img 
          src={`https://s3.amazonaws.com/sketchdeck/${props.project._id}/${props.asset.id}/${currentVersion.UUID}`} 
          className="w-100 rounded"
          alt="Project Asset"
        /> 
      </div> 
      <div className="asset-meta p-2">
        <div>{title}</div>
        <div className="asset-date">
          Last Updated: 
          {' '}
          {moment(currentVersion.createdAt).fromNow()}
        </div> 
      </div>
    </div>
  )
}

export default AssetPreview;

