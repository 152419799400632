import pdfjs from 'pdfjs-dist';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export function prepFilesForUpload(files) {
  return new Promise((resolve, reject) => {
    const data = new FormData();
    const promises = [];
    for (let i=0; i < files.length; i++) { 
      if (files[i].type === 'application/pdf') {
        promises.push(new Promise((resolveInt, rejectInt) => {
          const reader = new FileReader();
          reader.onload = (file) => {
            const loadingTask = pdfjs.getDocument(URL.createObjectURL(files[i])); 
            loadingTask.promise.then(doc => {
              if (doc.numPages > 1) {
                rejectInt('Sorry, multi-page PDFs are not currently supported');                    
              } else {
                data.append('files', files[i]);
                resolveInt();
              }
            });
          } 
          reader.readAsArrayBuffer(files[i]);
        }));
      } else {
        data.append('files', files[i]);
        promises.push(Promise.resolve());
      }
    }
    Promise.all(promises)
      .then(() => {
        resolve(data);
      })
      .catch((err) => {
        console.log('Rejecting....');  
        reject(err);
      });
  })
}

