const axios = require('axios');

let apiURL = 'https://dev.api.reviewcycle';
if (process.env.NODE_ENV === 'production') {
  apiURL = 'https://api.reviewcycle.io';
}

const getHeaders = () => {
  return new Promise((resolve, reject) => {
    return resolve({});
  });
}

const service = axios.create({
  baseURL: apiURL,
  headers: getHeaders(),
});

export const get = (url, options) => {
  const opts = { ...options };
  return new Promise(async (resolve, reject) => {
    service.get(url, {
      ...opts, 
    })
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
}

export const post = (url, body, headers) => { 
  return new Promise((resolve, reject) => {
    service.post(url, body, { headers })
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
}

export const put = (url, body, options) => {
  const opts = { ...options }; 
  return new Promise(async(resolve, reject) => {
    service.put(url, body, {
      ...opts, 
    })
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
}
