import React from 'react';
import NoteListItem from '../NoteListItem';
require('./styles.scss');

const blankPaperIcon = require('../../img/blank-paper.png');

const Notes = (props) => {

  const onHoverNoteItem = (note) => {
    props.onHoverNote(note);
  }

  return (
    <div className="notes">
      {props.notes.length > 0 && props.notes.map(note => {
        if (!note.deleted) {
          return (
            <NoteListItem
              key={note._id}
              note={note}
              openNote={props.openNote}
              onHoverNoteItem={onHoverNoteItem}
            />
          );
        } 
        return '';
      })}
      {props.notes.filter(n => !n.deleted).length === 0 &&
        <div className="h-100 w-100 d-flex align-items-center justify-content-center flex-column pt-3">
          <img src={blankPaperIcon} alt="blank paper icon" className="empty-icon" />
          <h5>No one has left a note yet.</h5>
          <p>Go ahead, leave a note and get the discussion going!</p>
        </div>
      }
    </div>
  );
}

export default Notes;
