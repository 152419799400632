import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
require('./styles.scss');

const Tab = (props) => {
  const {
    component,
    name,
  } = props;

  const tabClasses = classNames(
    'tab',
    'd-flex',
    'align-items-center',
    'justify-content-center',
    {
      selected: props.isSelected,
    }
  );

  const onClickTab = () => {
    props.onClickTab(component);
  }

  return (
    <div
      className={tabClasses}
      onClick={onClickTab}
    >
      {name} 
    </div>
  );
}

Tab.propTypes = {
  component: PropTypes.string,
  name: PropTypes.string,
  isActive: PropTypes.bool,
}

export default Tab;
