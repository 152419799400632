const isProd = (process.env.NODE_ENV === 'production');

let apiUrl = 'https://api.reviewcycle.io';
let loginCallback = 'https://reviewcycle.io/login/callback';

if (!isProd) {
  apiUrl = 'https://dev.api.reviewcycle';
  loginCallback = 'https://dev.www.reviewcycle/login/callback';
}

module.exports = {
  apiUrl,
  loginCallback,
}
