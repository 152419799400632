import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import history from './util/history';

import NotFound from './components/404';
import Admin from './components/Admin';
import Dashboard from './components/Dashboard';
import Home from './components/Home';
import ProjectDetail from './components/ProjectDetail';
import SecuredRoute from './components/SecuredRoute';

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <SecuredRoute path="/dashboard" component={Dashboard } />
          <SecuredRoute path="/p/:id" component={ProjectDetail} />
          <Route path="/admin" component={Admin} />
          <Route path="/" exact component={Home} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default App;
