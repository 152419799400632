import React, { Component } from 'react';
import { get } from '../../util/request';

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      stats: {},
    }
  }

  componentDidMount() {
    get('/stats')
      .then(({data}) => {
        this.setState({ stats: data }); 
      })
      .catch(() => {
        alert('Sorry, I fucked up. Ask Justin what\'s wrong');
      });
  }
    
  render() {
    const {
      stats,
    } = this.state;

    return (
      <div className="container">
        <h1>Is this thing on?</h1>
        <table>
          <thead>
            <tr>
              <td>Date</td>
              <td>Projects Created</td>
              <td>Messages Sent</td>
            </tr>
          </thead>
          <tbody>
            { Object.keys(stats).map(s => (
              <tr key={s}>
                <td>{s}</td>
                <td className="text-center">{stats[s].projects}</td>
                <td className="text-center">{stats[s].messages}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Dashboard;
