import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

class LB extends Component {
  render() {
    const { isOpen, imageSrc, onClose } = this.props; 
    return (
      <div>
        {isOpen &&
          <Lightbox
            mainSrc={imageSrc}
            onCloseRequest={onClose}
          />
        }
      </div>
    )
  }
}

export default LB;
