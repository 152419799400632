import React, { Fragment, div, createRef, useState, useEffect } from 'react';
import ReactDom from 'react-dom';
import Dropzone from 'react-dropzone';
import { DragDropContext, Droppable, Draggable } from  'react-beautiful-dnd';
import { convertToRaw } from 'draft-js';
import Modal from 'react-modal';
import queryString from 'query-string';
import Loading from '../Loading';
import AssetService from '../../services/asset.service';
import FileService from '../../services/file.service';
import NoteService from '../../services/note.service';
import ProjectService from '../../services/project.service';
import { prepFilesForUpload } from '../../util/assets';

import { useAuth0 } from '../Auth';
import AssetPreview from '../AssetPreview';
import Canvas from '../Canvas';
import contentEditable from '../Editable';
import Error from '../Error';
import Lightbox from '../Lightbox';
import Nav from '../Nav';
import NotePanel from '../NotePanel';
import NotFound from '../404';
import ShareBanner from '../ShareBanner';
import TabContainer from '../TabContainer';
import TextEditor from '../TextEditor';
import Upload from '../Upload';
import Versions from '../Versions';

require('./styles.scss');
require('../../../node_modules/draft-js/dist/Draft.css');

const s3Path = 'https://s3.amazonaws.com/sketchdeck';

const ProjectDetail = (props) => {

  const aboutPlaceholder = 'Type here to tell people what this is';

  const { loading, user } = useAuth0();
  
  const [activeAnnotation, setActiveAnnotation] = useState({});
  const [activeAsset, setActiveAsset] = useState({});
  const [activeAssetFileVersion, setActiveAssetFileVersion] = useState(0);
  const [activeFileNotes, setActiveFileNotes] = useState([]);
  const [activeNote, setActiveNote] = useState(null);
  const [assets, setAssets] = useState([]);
  const [canvasSettings, setCanvasSettings] = useState({});
  const [canvasSetup, setCanvasSetup] = useState(false);
  const [dropZoneRef, setDropZoneRef] = useState(null);
  const [error, setError] = useState('');
  const [fetchedProject, setFetchedProject] = useState(false);
  const [focusedNote, setFocusedNote] = useState({});
  const [hasParsedUrl, setHasParsedUrl] = useState(false);
  const [initiallyOpenAsset, setInitiallyOpenAsset] = useState(null);
  const [initiallyOpenNote, setInitiallyOpenNote] = useState(null);
  const [isCreatingNote, setIsCreatingNote] = useState(false);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [isLoadingProject, setIsLoadingProject] = useState(true);
  const [isNotePanelOpen, setIsNotePanelOpen] = useState(false);
  const [notes, setNotes] = useState([]);
  const [noteNumber, setNoteNumber] = useState('');
  const [project, setProject] = useState(null);
  const [projectSlug, setProjectSlug] = useState('');
  const [showVersionNotesModal, setShowVersionNotesModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadingNewVersion, setUploadingNewVersion] = useState(false);
  const [windowHeight, setWindowHeight] = useState(0);
  const [versionEditorState, setVersionEditorState] = useState({});

  const assetRef = React.createRef();

  useEffect(() => { 
    if (!projectSlug) setProjectSlug(props.match.params.id);
    if (!windowHeight) setWindowHeight(window.height);

    // if we don't have a project, go get one
    if (!fetchedProject && projectSlug) {
      getProject(projectSlug);
    }

    // if we haven't set up a ref to the dropzone, do that
    if (!dropZoneRef) {
      setDropZoneRef(createRef());
    }
  
    if (assetRef.current) {
      const resizeTimer = () => {
        setTimeout(() => {
          if (assetRef.current) {
            setCanvasSettings({
              width: assetRef.current.width,
              height: assetRef.current.height, 
            });
          }
          setWindowHeight(window.innerHeight); 
        }, 200)
      };

      clearTimeout(resizeTimer);
      window.addEventListener("resize", resizeTimer);
    }

    Modal.setAppElement('body');
  });  

  // this checks to see if a project has been set and updates the owner if necessary
  // TODO: this is really inefficient, running 3 or more times at once. Fix this bullshit.
  useEffect(() => {
    if(project && !project.owner && user) {
      const projectKey = localStorage.getItem(`rc-project-key-${project._id}`);
      if (projectKey) updateProjectWithOwner(projectKey);
    }
  });

  const cancelAssetNote = () => {
    setIsCreatingNote(false);
  }

  const handleImageDidLoad = () => {
    setCanvasSetup(false);
    setupCanvas();
  }

  const setupCanvas = () => {
    if (assetRef.current && !canvasSetup) {
      if (assetRef.current.height === 0) return;
      setCanvasSettings({
        ...canvasSettings,
        height: assetRef.current.height,
        width: assetRef.current.width,
      });
      setCanvasSetup(true);
    } 
  }

  useEffect(() => {
    parseUrl(); 
    console.log('user', user);
  }, []);

  //this is used to deep link to assets and notes
  const parseUrl = () => {
    const qs = queryString.parse(props.location.search);
    if (qs.asset) {    
      setInitiallyOpenAsset(qs.asset);
    }
    if (qs.note) {
      setInitiallyOpenNote(qs.note);
    }
  }

  const getProject = (slug) => {
    setFetchedProject(true);
    ProjectService.getProject(slug)
      .then(({project, assets, notes}) => {
        setAssets(assets);
        setIsLoadingProject(false);
        setProject(project);
        setNotes(notes);

        if (initiallyOpenAsset && !hasParsedUrl) {
          setSelectedAsset(assets.find(asset => asset._id === initiallyOpenAsset)); 

          if (initiallyOpenNote) {
            setActiveNote(notes.find(note => note._id === initiallyOpenNote));
            setIsNotePanelOpen(true);
          }
          return setHasParsedUrl(true);
        }

        if (Object.keys(activeAsset).length === 0) {
          setSelectedAsset(assets[0]);
        } else {
          const activeAssetIdx = assets.findIndex(a => a.id === activeAsset.id);
          setSelectedAsset(assets[activeAssetIdx]);
        } 
      })
      .catch((err) => {
        console.log('Error getting project', err);
      });
  }

  const afterAssetDrop = (acceptedFiles) => {
    setUploading(true);

    prepFilesForUpload(acceptedFiles)
      .then((data) => {
        ProjectService.uploadAssets(project._id, data)
          .then((response) => { 
            getProject(projectSlug); 
            setUploading(false);
          })
          .catch(e => console.log(e));
        })
        .catch((error) => {
          setError({error});
          setUploading(false);
        })
  }

  const afterNewVersionDrop = (acceptedFiles) => {
    setUploadingNewVersion(true);

    const data = new FormData();
    for (let i=0; i < acceptedFiles.length; i++) { 
      data.append('files', acceptedFiles[i]);
    }

    AssetService.addNewFileToAsset(activeAsset._id, data)
      .then((asset) => { 
        getProject(projectSlug); 
        setUploadingNewVersion(false);
        setShowVersionNotesModal(true);
        setActiveAsset(asset);
        setActiveAssetFileVersion(asset.files.length - 1);
        setActiveFileNotes([]);
      })
      .catch(e => console.log(e)); 
  } 

  const toggleNoteMode = () => {
    setIsCreatingNote(!isCreatingNote);
    setNoteNumber(activeFileNotes.length + 1); 
  }

  const manuallyAddVersion = () => {
    dropZoneRef.current.open();
  } 

  const onChangeVersionText = (editorState) => {
    setVersionEditorState(editorState);
  }
 
  const onCloseVersionNotesModal = () => {
    setShowVersionNotesModal(false);
  }

  const onToggleLightbox = () => {
    setIsLightboxOpen(!isLightboxOpen);
  }

  const onDragEndAsset = ({draggableId, source, destination}) => {
    let currAssets = [...assets];
    const assetBeingMoved = currAssets.find(a => a._id === draggableId);
    currAssets.splice(source.index, 1);
    currAssets.splice(destination.index, 0, assetBeingMoved);
    currAssets = currAssets.map((a, idx) => {
      a.order = idx;
      return a;
    });
    setAssets(currAssets);

    ProjectService.updateAssetOrder(project._id, currAssets)
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  }

  const onCloseNotePanel = () => {
    setIsNotePanelOpen(false);
  }

  const onHoverNoteInList = (note) => {
    if (note._id === focusedNote._id) return;
    setFocusedNote(note);
  }

  const onOpenNotePanel = (note) => {
    setIsNotePanelOpen(true);
    setActiveNote(note);
  }

  const onSwitchAssetVersion = (UUID) => {
    const fileIndex = activeAsset.files.findIndex(f => f.UUID === UUID);
    ReactDom.unstable_batchedUpdates(() => {
      setActiveAssetFileVersion(fileIndex); 
      setCanvasSetup(false);
    });
 
    NoteService.getNotesForFile(activeAsset.files[fileIndex]._id)
      .then(notes => {
        setActiveFileNotes(notes);
      })
      .catch(e => console.log('Fucked up getting the notes'));
  }

  const saveVersionNotes = () => {
    const fileId = activeAsset.files[activeAsset.files.length - 1]._id;

    FileService.updateFile(fileId, {
      versionNotes: JSON.stringify(convertToRaw(versionEditorState.getCurrentContent())),
    })
      .then((file) => {
        const fileIndex = activeAsset.files.findIndex(f => f._id === file._id);
        activeAsset.files[fileIndex] = file;
        setActiveAsset(activeAsset);
        setShowVersionNotesModal(false); 
      })
      .catch((e) => console.log('Shit the bed', e));
  }

  const saveAssetNote = ({body, from, number}) => {  
    NoteService.createNote(
      activeAsset.files[activeAssetFileVersion]._id,
      body,
      from,
      number,
      activeAnnotation,
    )
      .then((note) => {
        setIsNotePanelOpen(false);
        setIsCreatingNote(false);
        setActiveFileNotes([...activeFileNotes, note]); 
      })
      .catch((e) => console.log('Error', e));
  }

  const onFinishDrawingAnnotation = (activeRect) => {
    setActiveAnnotation(activeRect);
    setIsCreatingNote(true); 
  }

  const setSelectedAsset = (asset) => {
    if (asset._id === activeAsset._id) return;
    AssetService.getAsset(asset._id)
      .then((asset) => {
        ReactDom.unstable_batchedUpdates(() => {
          setCanvasSetup(false);
          setActiveAssetFileVersion(asset.files.length - 1);
          setActiveAsset(asset);
        });
        NoteService.getNotesForFile(asset.files[asset.files.length - 1]._id)
          .then((notes) => {
            setActiveFileNotes(notes)
          })
          .catch((e) => {
            console.log('Fucked up getting the notes')
          });
      })
      .catch((e) => {
        console.log('Error getting asset', e)
      });
  }

  const updateAssetDescription = (description) => {
    if (description === '' || !description) return;
    AssetService.updateAsset(activeAsset._id, { description })
      .catch(e => console.log(e));
  }

  const updateAssetList = (asset) => {
    const idx = assets.findIndex(a => a._id === asset._id);
    assets[idx] = Object.assign(assets[idx], asset); 

    if (asset._id === activeAsset._id) {
      setActiveAsset(asset);
    }
  }

  const updateNote = (noteId, body) => {
    NoteService.updateNote(noteId, body)
      .then((updatedNote) =>{
        const updatedFileNotes = activeFileNotes.slice(0);
        updatedFileNotes[activeFileNotes.findIndex(n => n._id === noteId)] = updatedNote;
        setActiveFileNotes(updatedFileNotes);
        setActiveNote(updatedNote); 
      })
      .catch((e) => console.log(e));
  }

  const updateProjectWithOwner = (projectKey) => {
    ProjectService.updateProject(project._id, { owner: user.sub})
      .then((project) => {
        setProject(project); 
      })
      .catch((e) => console.log('There was an error updating the project'));
  }

  // if we can't find it, return an error

  if (error === '404') return <NotFound />;

  // if we're still loading, let em know

  if (!activeAsset.files) return <Loading />;

  if (!project) return <Loading />;

  const EditableP = contentEditable('p');

  assets.sort((a, b) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  });

  if (activeAssetFileVersion === undefined) {
    setActiveAssetFileVersion(activeAsset.files.length - 1);
  }

  const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '600px',
    }
  }

  if (!user) return 'Loading...';

  return(
    <Fragment>  
      <Dropzone onDrop={afterAssetDrop} noClick>
        {({getRootProps, getInputProps}) => ( 
          <div className="h-100">
            <Nav project={project} />
            <ShareBanner />
            <div className="container-fluid h-100 project-detail">
              {error &&
                <Error
                  errorMessage={error}
                />
              }
              <div className="row h-100"> 
                <div className="col-2 h-100 overflow-auto asset-list-column pt-4">
                  <h6>Your Assets</h6>
                  <div className="mb-3 asset-upload">
                    <Upload afterDrop={afterAssetDrop} loading={uploading} />
                  </div>
                  <DragDropContext onDragEnd={onDragEndAsset}>
                    <Droppable droppableId="asset-list">
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {assets.map((asset,idx) => (
                            <Draggable 
                              draggableId={asset._id}
                              index={asset.order || idx}
                              key={asset._id}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <AssetPreview
                                    project={project}
                                    asset={asset}
                                    onClick={setSelectedAsset}
                                    onUpdate={updateAssetList}
                                    selected={activeAsset.id === asset.id}
                                    key={asset._id}
                                  />    
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
                <div
                  className="col-6 h-100 overflow-auto d-flex flex-column align-items-center pt-4"
                >
                  <Versions 
                    asset={activeAsset}
                    selectedFile={activeAssetFileVersion}
                    onSwitchVersion={onSwitchAssetVersion}
                    onAddVersion={manuallyAddVersion}
                    onUpdate={updateAssetList}
                    onZoomImage={onToggleLightbox}
                  />
                  {uploadingNewVersion &&
                    <div className="text-center">
                      <i className="fas fa-spinner fa-spin fa-3x mb-3" />
                      <h5>Uploading new version...</h5>
                    </div>
                  }
                  {!uploadingNewVersion &&
                    <Dropzone 
                      noClick
                      onDrop={afterNewVersionDrop}
                      ref={dropZoneRef}
                      accept="image/*,application/pdf"
                    >
                      {({getRootProps, getInputProps}) => ( 
                        <div 
                          {...getRootProps()} 
                          className="h-100 asset-stage"
                          style={{outline: 'none'}}
                        >
                          <div> 
                            <input {...getInputProps()} className="h-100"/> 
                            <div className="position-sticky text-center">   
                              <Canvas
                                activeAsset={activeAsset}
                                activeAssetFileVersion={activeAssetFileVersion}
                                focusedNote={focusedNote}
                                height={canvasSettings.height}
                                width={canvasSettings.width}
                                openNotesPanel={onOpenNotePanel}
                                onCancelDrawing={cancelAssetNote}
                                onFinishDrawing={onFinishDrawingAnnotation}
                                notes={activeFileNotes}
                                isCreatingNote={isCreatingNote}
                                windowHeight={windowHeight}
                              />
                              <img
                                src={`${s3Path}/${project._id}/${activeAsset.id}/${activeAsset.files[activeAssetFileVersion].UUID}`}
                                className="asset mw-100"
                                alt="Asset" 
                                ref={assetRef}
                                onLoad={handleImageDidLoad}
                              />
                            </div>
                          </div>
                          <Lightbox
                            imageSrc={`${s3Path}/${project._id}/${activeAsset.id}/${activeAsset.files[activeAssetFileVersion].UUID}`}
                            isOpen={isLightboxOpen}
                            onClose={onToggleLightbox}
                          />
                        </div>
                      )}
                    </Dropzone>
                  }
                </div>
                <div className="col-4 px-2 pt-4">
                  <div className="position-sticky">
                    <div>
                      <h6>About this</h6>
                      <div className="mb-5 border p-3 rounded">
                        <EditableP 
                          value={activeAsset.description ? activeAsset.description : aboutPlaceholder}
                          onSave={updateAssetDescription}
                          placeholder={aboutPlaceholder}
                        />
                      </div>
                    </div> 
                    <div className="mt-3">
                      <h6>Design feedback</h6>
                      <TabContainer
                        activeAsset={activeAsset}
                        activeFileNotes={activeFileNotes}
                        onHoverNote={onHoverNoteInList}
                        toggleNoteMode={toggleNoteMode}
                        noteModeEnabled={isCreatingNote}
                        openNote={onOpenNotePanel}
                      />
                    </div> 
                  </div>
                </div>
                <NotePanel 
                  isCreatingNote={isCreatingNote}
                  isOpen={isNotePanelOpen}
                  closePanel={onCloseNotePanel}
                  onCancelNote={cancelAssetNote}
                  onSaveNote={saveAssetNote}
                  activeNote={activeNote}
                  onUpdateNote={updateNote}
                  noteNumber={noteNumber}
                />
              </div> 
            </div>
          </div>
        )}
      </Dropzone> 
      <Modal
        isOpen={showVersionNotesModal}
        onRequestClose={onCloseVersionNotesModal}
        shouldCloseOnOverlayClick={false}
        style={modalStyles}
      >
        <h3>What's new in this version?</h3>
        <p>Leave a quick summary of the changes, so everyone is caught up.</p>
        <div className="border-top border-bottom pt-3 mb-3">
          <TextEditor onChange={onChangeVersionText} />
        </div>
        <button className="btn btn-primary mr-2" onClick={saveVersionNotes}>Save Notes</button>
        <button className="btn btn-outline-secondary" onClick={onCloseVersionNotesModal}>Don't Add Notes</button>
      </Modal>
    </Fragment>
  );
}

export default ProjectDetail;
