import React from 'react';

const pencil = require('../../img/broken-pencil.png');

const NotFound = () => {
  return (
    <div className="text-center" style={{marginTop: '100px'}}>
      <h2>Oops, you reached a page that doesn't exist.</h2>
      <p className="mb-5">Check the address and try again, or, <a href="/">head back home.</a></p>
      <img src={pencil} width="700px"/>
    </div>
  );
}

export default NotFound;
