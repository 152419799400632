import React, {Component} from 'react';
import {Editor, EditorState, RichUtils} from 'draft-js';

require('./styles.scss');

const useFA = (faIconName) => {
  const className=`fas fa-${faIconName} fa-fw`;
  return <i className={className}></i>; 
}

const blockTypes = [
  {label: useFA('h1'), style: 'header-one'},
  {label: useFA('h2'), style: 'header-two'},
  {label: useFA('h3'), style: 'header-three'},
  {label: useFA('list-ul'), style: 'unordered-list-item'},
  {label: useFA('list-ol'), style: 'ordered-list-item'},
];

const inlineStyles = [
  {label: useFA('bold'), style: 'BOLD'},
  {label: useFA('italic'), style: 'ITALIC'},
  {label: useFA('underline'), style: 'UNDERLINE'},
];

const BlockStyleControls = (props) => {
  const {editorState} = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <span className="RichEditor-controls">
      {blockTypes.map((t) => 
        <EditorButton
          key={t.label}
          active={t.style === blockType}
          label={t.label}
          onToggle={props.onToggle}
          style={t.style}
        />
      )}
    </span>
  );
}

const InlineStyleControls = (props) => {
  const currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <span className="RichEditor-controls">
      {inlineStyles.map(t =>
        <EditorButton
          key={t.label}
          active={currentStyle.has(t.style)}
          label={t.label}
          onToggle={props.onToggle}
          style={t.style}
        />
      )}
    </span>
  );
}

class EditorButton extends Component {
  onToggle = (e) => {
    e.preventDefault();
    this.props.onToggle(this.props.style);
  }

  render() {
    let className = 'RichEditor-styleButton';
    if (this.props.active) {
      className += ' RichEditor-activeButton';
    }

    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}

class TextEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(),
    };
  }

  componentDidMount() {
    this.refs.editor.focus();
  }

  handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(this.state.editorState, command);
    if (newState) {
      this.onChange(newState);
    }
  }

  onChange = (editorState) => {
    this.setState({ editorState });
    if (this.props.onChange) this.props.onChange(editorState);
  }

  toggleBlockType = (blockType) => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  }

  toggleInlineStyle = (inlineStyle) => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle));
  }

  render() {
    const {
      state: {
        editorState,
      },
      onChange,
      handleKeyCommand,
    } = this; 

    return (
      <div className="editorContainer">
        <InlineStyleControls
          editorState={editorState}
          onToggle={this.toggleInlineStyle}
        />
        <BlockStyleControls
          editorState={editorState}
          onToggle={this.toggleBlockType}
        />
        <div className="mt-3 pb-5">
          <Editor
            editorState={editorState}
            onChange={onChange}
            handleKeyCommand={handleKeyCommand}
            placeholder="Type your notes..."
            ref="editor"
            spellCheck={true}
          />
        </div>
      </div>
    );
  }
}

export default TextEditor;
