import { get, post, put } from '../util/request';

class AssetService {
  uploadAsset(projectId) {
    return new Promise((resolve, reject) => {
      post(`/projects/${projectId}/files`)
        .then((projects) => resolve(projects.data))
        .catch(() => reject(new Error('There was an error getting the projects')))
    });
  } 
  createAsset(body) {
    return new Promise((resolve, reject) => {
      post('/assets', body)
        .then((asset) => resolve(asset.data))
        .catch(() => reject(new Error('There was an error creating the asset')))
    });
  }
  getAsset(id, options) {
    return new Promise((resolve, reject) => {
      get(`/assets/${id}`, options)
        .then((asset) => resolve(asset.data))
        .catch(() => reject(new Error('There was an error getting the asset')));
    });
  }
  updateAsset(id, body) {
    return new Promise((resolve, reject) => {
      put(`/assets/${id}`, body)
        .then((asset) => resolve(asset.data))
        .catch(() => reject(new Error('There was an error updating the asset')));
    });
  }
  createAssetComment(assetId, body) {
    return new Promise((resolve, reject) => {
      post(`/assets/${assetId}/comments`, body)
        .then((comment) => resolve(comment.data))
        .catch(() => reject(new Error('There was an error creating the comment')));
    });
  } 
  addNewFileToAsset(assetId, body) {
    return new Promise((resolve, reject) => {
      post(`/assets/${assetId}/files`, body)
        .then((response) => resolve(response.data))
        .catch(() => reject(new Error('There was an error uploading the file')));
    });
  }
}

export default new AssetService();
