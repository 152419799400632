import React, { Component, Fragment } from 'react';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';

require('./styles.scss');

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
    }
  }

  afterDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      this.setState({ error: 'rejected-files' }); 
      setTimeout(() => {
        this.setState({ error: '' }); 
      }, 3000);
      return;
    }
    if (this.props.afterDrop) this.props.afterDrop(acceptedFiles);
  }

  render() {
    const {
      afterDrop,
      state: {
        error,
      }
    } = this;

    const uploadClasses = classNames(
      'upload-asset',
      'd-flex',
      'align-items-center',
      'justify-content-center',
      'rounded',
      {
        'flex-column h-100 full-screen': this.props.fullScreen
      }
    );

    let uploadPrompt = 'Add another file';
    if (this.props.fullScreen) uploadPrompt = 'Start instantly. Upload something now.';

    return (
      <Dropzone onDrop={afterDrop} accept="image/*,application/pdf">
        {({getRootProps, getInputProps}) => (
          <div {...getRootProps()} 
            className={uploadClasses}
          >
            <input {...getInputProps()} />
            {this.props.fullScreen &&
              <div>
                {!this.props.loading &&
                  <div className="text-center">
                    <i className="fas fa-2x fa-arrow-up" />
                    <p className="mb-0 mt-2">{this.props.prompt || uploadPrompt}</p> 
                  </div>
                }
                {this.props.loading &&
                  <div className="text-center">
                    <i className="fas fa-spinner-third fa-spin" />
                    <p className="mt-3"><small>Creating your review room...</small></p>
                  </div>
                }
              </div>
            }
            {!this.props.fullScreen &&
              <div className="d-flex flex-column text-center p-3">
                <div>
                  {!this.props.loading &&
                    <Fragment>
                      <i className="fal fa-plus mr-2" />
                      <span className="mb-0"><small>{this.props.prompt || uploadPrompt}</small></span>   
                    </Fragment>
                  }
                  {this.props.loading &&
                    <i className="fas fa-spinner-third fa-spin" />
                  }
                </div>
              </div>
            }
            {error &&
              <p className="error"><small>Sorry, only image files are currently supported.</small></p>  
            }
          </div>
        )}
      </Dropzone>
    );
  }
}

export default Upload;
