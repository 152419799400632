import { get, post, put } from '../util/request';

class ProjectsService {
  getProjects(userIdToken) {
    return new Promise((resolve, reject) => {
      get('/projects', {
        headers: {
          'Authorization': `Bearer ${userIdToken}`,
        }
      })
        .then((projects) => resolve(projects.data))
        .catch((err) => reject(new Error(err)))
    });
  }
  createProject(body) {
    return new Promise((resolve, reject) => {
      post('/projects', body)
        .then((project) => resolve(project.data))
        .catch(() => reject(new Error('There was an error creating the project')))
    });  
  }
  getProject(id) {
    return new Promise((resolve, reject) => {
      get(`/projects/${id}`, {
          headers: {
            'X-ReviewCycle-Keys': localStorage.getItem('RCKeys'),
          }
        })
        .then((project) => resolve(project.data))
        .catch((err) => reject(new Error('There was an error retrieving the project', err)))
    });
  }
  updateProject(id, body, userIdToken) {
    return new Promise((resolve, reject) => {
      put(`/projects/${id}`, body, {
        headers: {
          'Authorization': `Bearer ${userIdToken}`,    
        }
      })
      .then((project) => resolve(project.data))
      .catch(() => reject(new Error('There was an error updating the project')))
    });
  }
  uploadAssets(id, body) {
    return new Promise((resolve, reject) => {
      post(`/projects/${id}/assets`, body, {
          'Content-Type': 'multipart/form-data',
        })
        .then((projects) => resolve(projects.data))
        .catch((err) => reject(new Error('There was an error uploading the assets', err)))
    });
  }
  updateAssetOrder(projectId, assets) {
    return new Promise((resolve, reject) => {
      put(`/projects/${projectId}/assets/order`, { assets })
        .then(() => console.log('Done'))
        .catch(() => console.log('Error'));
    });
  }
}

export default new ProjectsService();
