import React, { Fragment, useState } from 'react';
import { Circle, Rect, Text } from 'react-konva';

const Rectangle = (props) => {
  const [strokeColor, setStrokeColor] = useState(props.strokeColor);

  const onRectangleMouseOver = () => {
    if (props.isDrawing) return;
    setStrokeColor('blue'); 
  }

  const onRectangleMouseOut = () => {
    setStrokeColor(props.strokeColor);
  }

  const onRectangleClick = () => {
    props.onClick(props.note);
  }

  return (
    <Fragment> 
      <Rect
        annotationId={props.annotationId}
        key={props.annotationId}
        x={props.startX}
        y={props.startY}
        width={props.width}
        height={props.height}
        stroke={strokeColor}
        strokeWidth={props.strokeWidth || 2}
        onMouseOver={onRectangleMouseOver}
        onMouseOut={onRectangleMouseOut}
        onClick={onRectangleClick}
      />
      {props.annotationId &&
        <Fragment>
          <Circle
            x={props.startX + 15}
            y={props.startY}
            radius={10}
            fill={strokeColor}
            stroke="#ffffff"
            strokeWidth={1}
          />
          <Text
            x={props.startX + 12}
            y={props.startY - 4}
            text={props.number}
            fill="#ffffff"
            fontFamily="Roboto"
            fontSize={10}
            fontStyle="bold"
          />
        </Fragment>
      }
    </Fragment>
  );
}

export default Rectangle;
