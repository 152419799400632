import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import Linkify from 'react-linkify';
import renderHTML from 'react-render-html';

require('./styles.scss');

const prepMessageBody = (body) => {
  if (body) {
    return body.replace(/\n/g, '<br />');
  }
  return '';
}

const ChatMessageText = ({message}) => (
  <p className="mb-0 chat-message-body">
    <Linkify target='_blank' properties={{target: '_blank'}}>{renderHTML(prepMessageBody(message.body))}</Linkify>
  </p>
);

const ChatMessage = ({message, hideUser, isFirstMessage}) => {

  const padTop = hideUser || isFirstMessage;

  const classes = classNames('d-flex', {
    'mt-3': !padTop,
  });

  return (
    <div className="chat-message">
      {!hideUser &&
        <div className={classes}>
          {message.from.picture &&
            <img src={message.from.picture} className="chat-avatar mr-3" alt={`${message.from.name}`} />
          }
          {!message.from.picture &&
            <div className="person-initials">
              {message.from.givenName.substr(0,1)}{' '}{message.from.familyName.substr(0.1)}
            </div>
          }
          <div>
            <strong>{message.from.name}</strong>
            <span className="ml-2 chat-timestamp">{moment(message.createdAt).format('LT')}</span>
            <ChatMessageText message={message} />
          </div>
        </div>
      }
      {hideUser &&
        <div style={{marginLeft: '45px'}}>
          <ChatMessageText message={message} /> 
        </div>
      }
    </div>
  );
}

export default ChatMessage;
