import { put } from '../util/request';

class FileService { 
  updateFile(id, body) {
    return new Promise((resolve, reject) => {
      put(`/files/${id}`, body)
        .then((file) => resolve(file.data))
        .catch(() => reject(new Error('There was an error updating the file')));
    });
  } 
}

export default new FileService();
