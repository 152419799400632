import React from 'react';
import classNames from 'classnames';
import Emoji from '../Emoji';

require('./styles.scss');

const ChatNotification = ({message, isFirstMessage, type}) => {

  const classes = classNames(
    'd-inline-block',
    'notification-version',
    {
      'mt-3': !isFirstMessage,
    }
  );

  let icon;

  switch(type) {
    case 'original':
      icon = <Emoji symbol="🎉" />
      break;

    case 'new-version':
      icon = <Emoji symbol="👍" />
      break;

    default:
      icon = '';
      break;
  }
  return (
    <div style={{textAlign: 'center'}}>
      <span className={classes}>
        {message}
        <span className="ml-2">{icon}</span>
      </span>
    </div>
  );
}

export default ChatNotification;
