import React, { useEffect, useState } from 'react';
import { Stage, Layer } from 'react-konva';
import classNames from 'classnames';
import CanvasRectangle from '../CanvasRectangle';

require('./styles.scss');

const Canvas = (props) => { 
  const [isDrawingRect, setIsDrawingRect] = useState(false);
  const [annotations, setAnnotations] = useState([]);
  const [currentRect, setCurrentRect] = useState(null);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [currentPosition, setCurrentPosition] = useState({});
  const [color, setColor] = useState('red');
  const [lineThickness, setLineThickness] = useState('4');
  
  const canvasRef = React.createRef();

  const drawRectangles = () => {
    const rectangles = [];
    if (!props.notes) props.notes = [];

    props.notes.forEach(n => {
      if (!n.deleted) {
        const x = parseFloat(n.annotation.x);
        const y = parseFloat(n.annotation.y);
        const h = parseFloat(n.annotation.h);
        const w = parseFloat(n.annotation.w);

        const stageWidth = canvasRef.current.width();
        const stageHeight = canvasRef.current.height();
        let scaleFactorX = 1;
        let scaleFactorY = 1;

        if (stageWidth !== 0 && n.annotation.originalStageSize && n.annotation.originalStageSize.width && n.annotation.originalStageSize.height) {
          scaleFactorX = stageWidth / n.annotation.originalStageSize.width;
          scaleFactorY = stageHeight / n.annotation.originalStageSize.height;
        }

        const strokeColor = (n.resolved) ? '#43FF3B55' : '#2d89ff';

        rectangles.push(
          <CanvasRectangle
            key={n._id}
            annotationId={n._id}
            startX={x * scaleFactorX}
            startY={y * scaleFactorY}
            width={w * scaleFactorX}
            height={h * scaleFactorY}  
            strokeColor={strokeColor}
            note={n}
            onClick={onRectClick}
            number={n.number}
          />
        );
      }
    });
    setAnnotations(rectangles);
  } 

  useEffect(() => {
    setAnnotations([]);
    drawRectangles();
  });

  useEffect(() => {
    setCurrentRect(null);
  }, [props.activeAsset.id]);

  const onRectClick = (note) => {
    props.openNotesPanel(note);
  }

  const onClickCanvas = (e) => {
    if (!props.isCreatingNote) return;
    const {x, y} = e.target.getStage().getPointerPosition();
    setStartX(x);
    setStartY(y);
    setIsDrawingRect(true); 
  }

  const onMouseMoveCanvas = (e) => {
    if (!isDrawingRect) return;
    if (!props.isCreatingNote) return;  

    const {x, y} = e.target.getStage().getPointerPosition();

    setCurrentPosition({x, y}); 
    setCurrentRect(<CanvasRectangle
      startX={startX}
      startY={startY}
      width={x - startX}
      height={y - startY}
      onClick={onRectClick}
      strokeColor="#2d89ff"
      isDrawing
    />);
  }

  const onMouseUpCanvas = (e) => { 
    if (!isDrawingRect) return; 
    setIsDrawingRect(false);

    if (currentPosition.x === 0 || currentPosition.y === 0) return;

    props.openNotesPanel();
    props.onFinishDrawing({
      x: startX,
      y: startY,
      w: currentPosition.x - startX,
      h: currentPosition.y - startY,
      color,
      lineThickness,
      originalStageSize: {
        width: e.target.getStage().width(),
        height: e.target.getStage().height(),
      }
    });
    setCurrentPosition({x: 0, y: 0}); 
  }
 
  const shapes = [...annotations, currentRect]; 
  const canvasClasses = classNames('position-absolute', {
    'is-drawing': props.isCreatingNote,
  });

  return (
    <Stage
      width={props.width}
      height={props.height}
      className={canvasClasses}
      ref={canvasRef}
      onMouseDown={onClickCanvas}
      onMouseMove={onMouseMoveCanvas}
      onMouseUp={onMouseUpCanvas}
    > 
      <Layer> 
        {shapes.map((a) => {
          return a;
        })}
      </Layer>
    </Stage>
  );
}

export default Canvas;
