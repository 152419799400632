import React, { Fragment, useState } from 'react';
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import classNames from 'classnames';
import { useAuth0 } from '../Auth';
import Chat from '../Chat';
require('./styles.scss');

const NotePanel = (props) => {
  const [noteBody, setNoteBody] = useState(null);
  const { user } = useAuth0();

  const panelClasses = classNames('notes-panel', {
    open: props.isOpen,
  }); 

  const onChangeNoteBody = (e) => {
    setNoteBody(e.target.value);
  }

  const onClickResolve = () => {
    props.onUpdateNote(
      props.activeNote._id,
      {
        ...props.activeNote,
        resolved: true,
      }
    );
  }

  const onClickReopen = () => {
    props.onUpdateNote(
      props.activeNote._id,
      {
        ...props.activeNote,
        resolved: false,
      }
    );
  }

  const onClickDelete = () => {
    props.onUpdateNote(
      props.activeNote._id,
      {
        ...props.activeNote,
        deleted: true,
      }
    );
    props.closePanel();
  }

  const saveNote = (accessToken) => {
    props.onSaveNote({
      body: noteBody,
      from: user,
      number: props.noteNumber,
    });
  }

  const onClickSave = () => {
    saveNote();
  }

  const onClickCancel = () => {
    setNoteBody(null);
    props.closePanel();
    props.onCancelNote();
  }

  const onClosePanel = () => {
    props.closePanel();
  }
   
  return (
    <div className={panelClasses}> 
      {props.isCreatingNote &&
        <Fragment>
          <div className="note-number px-2 mb-2">
            Note {(props.activeNote && props.activeNote.noteNumber) || props.noteNumber}
          </div>
          <h5>Leave a note</h5>
          <textarea className="form-control" onChange={onChangeNoteBody} placeholder="Leave your note here"></textarea>
          <div className="d-flex align-items-center mt-3">
            <button className="btn btn-outline-primary ml-auto mr-2" onClick={onClickCancel}>Cancel</button>
            <button className="btn btn-primary" onClick={onClickSave}>Save Note</button>
          </div>
        </Fragment>
      }
      {!props.isCreatingNote && props.activeNote &&
        <Fragment>
          <div className="d-flex align-items-center mb-2">
            <i className="fal fa-times ml-auto fa-2x" onClick={onClosePanel}/>
          </div>
          <div className="d-flex align-items-center mb-3">
            <h3 className="m-0">Notes:</h3>
            <Dropdown as={ButtonGroup} className="ml-auto" alignRight>
              {!props.activeNote.resolved &&
                <Button variant="primary" onClick={onClickResolve}>
                  <i className="fal fa-check mr-2" />
                  Resolve Note
                </Button>
              }
              {props.activeNote.resolved &&
                <Button variant="primary" onClick={onClickReopen}>
                  <i className="fal fa-undo mr-2" />
                  Reopen Note
                </Button>
              }
              <Dropdown.Toggle split variant="primary" />
              <Dropdown.Menu>
                <Dropdown.Item onClick={onClickDelete}>
                  <i className="fal fa-trash-alt mr-2" />
                  Delete Note
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {props.activeNote.resolved &&
            <div className="resolved-banner p-3 w-100 mb-3 rounded">
              <i className="fal fa-lock mr-2" />
              This note has been resolved, and chat is locked.
            </div>
          }
          {props.activeNote &&
            <div className="border">
              <Chat
                channelId={props.activeNote._id}
                channelType="note"
                isLocked={props.activeNote.resolved}
                note={props.activeNote}
              />
            </div>
          }
        </Fragment>
      }
    </div>
  );
}

export default NotePanel;
