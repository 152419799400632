import React, {Component} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
require('./styles.scss');

class ShareBanner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      copied: false,
    }
  }

  onCopyLink = () => {
    this.setState({copied: true});
    setTimeout(() => {
      this.setState({copied: false});
    }, 5000);
  }

  render() {
    const location = `${window.location.host}${window.location.pathname}`;
    const {
      onCopyLink,
      state: {
        copied,
      },
    } = this;

    return (
      <div className="share-banner d-flex align-items-center justify-content-center p-2">
        <i className="far fa-rocket mr-2"></i> 
        <span>
          Share this project with your co-workers, clients, and friends:{' '}
          <CopyToClipboard 
            text={location}
            onCopy={onCopyLink}
          >
            <span>
              <strong>{location}</strong>
              <i className="fal fa-copy ml-2" />
            </span>
          </CopyToClipboard>
          {copied &&
            <span className="small ml-2">Copied!</span>
          }
        </span>
      </div>
    );
  }
}

export default ShareBanner;

