import { get, post, put } from '../util/request';

class NoteService { 
  createNote(file, body, from, number, annotation) {
    return new Promise((resolve, reject) => {
      post('/notes', {
          file,
          body,
          from,
          number,
          annotation
        })
        .then((n) => resolve(n.data))
        .catch(() => reject(new Error('There was an error creating the note')));
    });
  } 
  getNotesForFile(fileId) {
    return new Promise((resolve, reject) => {
      get(`/files/${fileId}/notes`)
        .then((notes) => resolve(notes.data))
        .catch((e) => reject(new Error(`There was an error getting the notes. API said: ${e}`)));
    });
  }
  updateNote(noteId, body) {
    return new Promise((resolve, reject) => {
      put(`/notes/${noteId}`, body)
        .then((updatedNote) => resolve(updatedNote.data))
        .catch(() => reject(new Error('There was an error updating the note')));
    });
  }
}

export default new NoteService();
