import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import TextareaAutosize from 'react-autosize-textarea';
import ChatMessage from '../ChatMessage';
import ChatNotification from '../ChatNotification';
import { useAuth0 } from '../Auth';
import socketIOClient from 'socket.io-client';
import MessageService from '../../services/message.service';
import { apiUrl } from '../../config';

require('./styles.scss');

const ChatTimeDivider = (props) => {
  const chatTimeDividerClasses = classNames(
    'chat-time-divider',
    'd-flex',
    'align-items-center',
    'justify-content-center',
    {
      'mt-3': !props.isFirstDay,
    }
  );

  return (
    <div className={chatTimeDividerClasses}>
      <hr className="mr-2" />
      {props.timestamp.format('dddd, MMMM Do')}
      <hr className="ml-2" />
    </div>
  )
};

const Chat = (props) => {
  const {
    asset,
    channelId,
    channelType,
  } = props;

  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const { user } = useAuth0();

  const socket = socketIOClient(`${apiUrl}`, { transports: ['websocket']}); 
  socket.on('messages', (messages) => {
    setMessages(messages); 
  });

  socket.on('connect_error', (err) => {
    console.log('SocketIO connection error:', err);
  }); 

  useEffect(() => {
    scrollToBottom();  //not quite perfect, flicker when switching to chat from notes
  }, [messages]);

  useEffect(() => {
    socket.emit('join', channelId);
    
    MessageService.getAssetMessages(channelId)
      .then((messages) => {
        setMessages(mergeChatMessages(messages));
      })
      .catch((e) => console.log(e));
    return () => {
      socket.emit('leave', channelId);
    }
  }, [channelId]);

  const mergeChatMessages = (messages) => {
    if (asset) {
      const versionNotifications = asset.files
        .sort((a,b) => {    
          return new Date(a.createdAt) - new Date(b.createdAt);
        })
        .map((file, idx) => {
          let message = `The design has been updated`;
          let notificationType = 'new-version';
          if (idx === 0) {
            message = 'The first version of this was uploaded';
            notificationType = 'original';
          }
          return {
            type: 'notification',
            message,
            createdAt: file.createdAt,
            id: idx,
            notificationType,
          };
        });
      return messages.map(m => {
        return {
          ...m,
          type: 'chat',
        };
      })
      .concat(versionNotifications)
      .sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      }) 
    }
    return messages;
  }

  const submitMessage = () => {

    delete user['https://reviewcycle.io/app_metadata'];
    socket.emit('message', 
      {
        channelId,
        channelType,
        from: user,
        body: message,
        type: 'chat',
      },
      channelId
    );
    setMessage('');
  }

  const handleInputKeyDown = (e) => {  
    if (e.key === 'Enter' && !e.shiftKey) {
      submitMessage();
    }
  }

  const handleInputChange = (e) => {
    let message = e.target.value;
    setMessage(message);
  }

  const scrollToBottom = () => {
    const chatPane = document.getElementById('chat-pane');
    const scrollHeight = chatPane.scrollHeight;
    const height = chatPane.clientHeight;
    const maxScrollTop = scrollHeight - height;
    chatPane.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  }

  let { note } = props;

  if (note && !note.from) note.from = {}; 

  if (message === '\n') setMessage('');

  const messageIsFromSamePerson = (prevMessage, currMessage) => {
    if (!prevMessage.type === 'chat' || !currMessage.type === 'chat') return false;
    if (!prevMessage.from || !currMessage.from) return false;
    return prevMessage.from.sub === currMessage.from.sub;
  }

  return (
    <div className="position-relative">
      <div className="chat-pane p-4" id="chat-pane">
        {props.note &&  
          <Fragment>
            <ChatTimeDivider timestamp={moment(note.createdAt)} isFirstDay />
            <ChatMessage
              message={note}
            /> 
          </Fragment>
        }
        {messages.map((m, idx) => {
          let message;
          let hideUser = false;
          let prevTimestamp;
          let isFirstMessageOfDay;
          const currTimestamp = moment(m.createdAt);

          const prevMessage = messages[idx - 1];

          if (idx > 0) {
            prevTimestamp = moment(messages[idx - 1].createdAt);
            isFirstMessageOfDay = prevTimestamp.isBefore(currTimestamp, 'day');
            const interval = 3; //don't display the username if the prev message is less than 3 min ago

            if (
              (currTimestamp.subtract(interval, 'minutes').isBefore(prevTimestamp)) &&
              prevMessage.type !== 'notification' &&
              messageIsFromSamePerson(prevMessage, m)
            ) {
              hideUser = true;
            }
          } 
          if (m.type === 'chat') {
            message = (
              <ChatMessage
                message={m}
                key={m._id}
                hideUser={hideUser}
                isFirstMessage={idx === 0 || isFirstMessageOfDay}
              />
            );
          }
          if (m.type === 'notification') {
            message = (
              <ChatNotification
                message={m.message}
                isFirstMessage={idx === 0 || isFirstMessageOfDay} 
                type={m.notificationType}
                key={m.id}
              />
            );
          }
          if (!prevTimestamp || prevTimestamp.isBefore(currTimestamp, 'day')) {
            return (
              <div>
                <ChatTimeDivider timestamp={currTimestamp} isFirstDay={idx === 0} key={`time-${m._id}`} />
                {message}
              </div>
            );
          }
          return message;
        })}
      </div>
      {!props.isLocked &&
        <div className="chat-controls p-3">
          <TextareaAutosize
            placeholder="Chat about it..."
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            value={message}
          ></TextareaAutosize>
        </div>
      }
    </div>
  );
}

export default Chat;
