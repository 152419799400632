import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Chat from '../Chat';
import Notes from '../Notes';
import NoteTools from '../NoteTools';
import Tab from '../Tab';
require('./styles.scss');

const TabContainer = (props) => {
  const {
    activeAsset,
  } = props;

  const [activeComponent, setActiveComponent] = useState('chat');

  useEffect(() => {
    onSelectTab(activeComponent);
  }, [activeAsset]);
 
  const tabs = [{
    index: 1,
    component: 'chat',
    name: 'Chat',
  }, {
    index: 2,
    component: 'notes',
    name: 'Notes',
  }];

  const onSelectTab = (component) => {
    setActiveComponent(component); 
  } 

  const chat = <Chat
    channelId={activeAsset._id}
    channelType="asset"
    asset={activeAsset} 
  />;

  const notes = (
    <div>
      <Notes notes={props.activeFileNotes} openNote={props.openNote} onHoverNote={props.onHoverNote} />
      <NoteTools toggleNoteMode={props.toggleNoteMode} noteModeEnabled={props.noteModeEnabled} />
    </div>
  );

  let activeTabComponent;
  switch(activeComponent) {
    case 'chat':
      activeTabComponent = chat;
      break;

    case 'notes':
      activeTabComponent = notes;
      break;

    default:
      activeTabComponent = chat;
      break; 
  }

  return (
    <div className="tab-container">
      <div className="d-flex align-items-center">
        {tabs.map(tab => (
          <Tab
            component={tab.component}
            name={tab.name}
            onClickTab={onSelectTab}
            isSelected={activeComponent === tab.component}
            key={tab.index}
          />
        ))}
      </div>
      <div className="border" style={{marginTop: '-1px', borderRadius: '5px'}}>
        {activeTabComponent}
      </div>
    </div>
  );
}

TabContainer.propTypes = {
  activeAsset: PropTypes.object,
};

export default TabContainer;
