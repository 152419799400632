import React, { useState } from 'react';
import {withRouter} from 'react-router-dom';

import { useAuth0 } from '../Auth';
import Error from '../Error';
import Upload from '../Upload';
import ProjectService from '../../services/project.service';
import {prepFilesForUpload} from '../../util/assets';

const annotatedScreenshot = require('../../img/home-screenshots/annotated-screenshot.png');
const assetListScreenshot = require('../../img/home-screenshots/asset-list.png');
const chatScreenshot = require('../../img/home-screenshots/chat.png');
const shareScreenshot = require('../../img/home-screenshots/share.png');
const versionNotesScreenshot = require('../../img/home-screenshots/version-notes.png');

require('./styles.scss');

const uuidv4 = require('uuid/v4');
const logo = require('../../img/logo.png');

const Home = (props) => {
  const [ error, setError ] = useState(null);
  const [ uploading, setUploading ] = useState(false); 
  const { user, loginWithRedirect } = useAuth0();

  const afterDropFiles = (files) => {
    setUploading(true);
    if (window.ga) {
      window.ga('send', 'event', 'upload', 'click');
    }
    const slug = uuidv4().substr(0,6);

    const payload = { slug };

    ProjectService.createProject(payload)
      .then((project) => {
        localStorage.setItem(`rc-project-key-${project._id}`, project.key);
        
        prepFilesForUpload(files)
          .then((data) => {
            ProjectService.uploadAssets(project._id, data)
              .then((response) => { 
                props.history.replace(`/p/${project.slug}`);
              })
              .catch((e) => {
                console.log('Error', e)
              });
            })
            .catch((error) => {
              setError(error);
              setUploading(false); 
            })
          }) 
      .catch((err) => console.log(err));
  }

  const trackDemoClick = () => {
    if (window.ga) {
      window.ga('send', 'event', 'demolink', 'click');
    }
  }

  const login = () => {
    loginWithRedirect({
      'redirect_uri': `${window.location.origin}/dashboard`,
    });
  }

  return (
    <div className="home">
      <div className="pt-5">
        <section className="text-center hero">
          <div className="d-flex align-items-center justify-content-center mb-5 container position-relative">
            <img src={logo} alt="ReviewCycle logo" className="" />
            <h6 className="mb-0 position-absolute" onClick={login} style={{cursor: 'pointer', right: 0}}>Log in to your account</h6>
          </div>
          <h1 className="mb-3">Get feedback on designs <br /><span className="purple">faster and easier.</span></h1>
          <h4 className="mb-5">
            <strong>ReviewCycle</strong> eliminates the back-and-forth that comes with getting <br /> 
            feedback on designs and other visual assets.
          </h4>
          {error &&
            <Error
              errorMessage={error}
            />
          }
          <Upload afterDrop={afterDropFiles} loading={uploading} fullScreen />
          <h4 className="mt-4">Want to see ReviewCycle in action? <a href="https://reviewcycle.io/p/19767e" onClick={trackDemoClick}>Try our live demo for free</a></h4>
        </section>  
        <section className="text-center intro">
          <h2>Simple. Fast. Easy. <span className="purple">As it should be.</span></h2>
          <h4 className="mb-5">
            ReviewCycle is built to be fast and dead simple to use. Easy for designers
            and their clients <br /> to collaborate. No setting up projects, no configuration. 
            Just upload and go.
          </h4>
          <img src={annotatedScreenshot} alt="ReviewCycle Screenshot" />
        </section>
        <section className="features text-center">
          <h2>Creative iteration is a <span className="purple">communication problem</span></h2>
          <h4 className="mb-5">
            Long email threads, imprecise feedback, long iteration loops - they all contribute <br />
            to frustration and lack of clarity in the review process. We're working
            to fix those problems.
          </h4>
          <div className="feature">
            <div className="mr-md-5">
              <h3>Upload one or a hundred assets (or more, you do you).</h3>
              <p>
                ReviewCycle makes it easy to review a single asset as it does hundreds
                at a time. Add whatever you want to a project, no limits.
              </p>
            </div>
            <img src={assetListScreenshot} alt="ReviewCycle Upload Screenshot" />
          </div>
          <div className="feature">
            <img src={chatScreenshot} alt="ReviewCycle Upload Screenshot" />
            <div className="ml-md-5">
              <h3>Eliminate ugly reply-all threads with real-time chat.</h3>
              <p>
                Real-time chat on an asset allows you and your team to discuss the design easily.
                Say goodbye to digging through your email to find the latest comment on something.
              </p>
            </div>
          </div>
          <div className="feature">
            <div className="mr-md-5">
              <h3>Document changes to each version so everyone's in the know.</h3>
              <p>
                Every asset can have unlimited versions, and each version features notes to keep 
                everyone in the loop on what's changed. Everything is documented and trackable.
              </p>
            </div>
            <img src={versionNotesScreenshot} alt="ReviewCycle Upload Screenshot" />
          </div>
          <div className="feature">
            <img src={shareScreenshot} alt="ReviewCycle Upload Screenshot" />
            <div className="ml-md-5">
              <h3>Share your design in less than 3 seconds.</h3>
              <p>
                Getting feedback shouldn't require a commitment. Share a link to your designs with 
                your colleagues, and they can instantly start reviewing. No complicated permissions,
                no obscure invitation system. Just easy, as it should be. 
              </p>
            </div>
          </div>
        </section> 
      </div>
      <footer className="mt-5 text-center">
        <h2>Use ReviewCycle <span className="purple">for free</span> while we're in beta!</h2>
        <p className="text-white mb-5">
          Right now, ReviewCycle is 100% free to use. We're working on enhanced features that will be part of a paid <br />
          subscription in late 2019. However, we'll <strong>always</strong> be committed to providing a free, quick way to get<br />
          feedback on your assets. No catch.
        </p>
        <Upload afterDrop={afterDropFiles} loading={uploading} fullScreen />
        <p className="mt-5">
          Made with <i className="fas fa-heart" style={{color:'red'}} /> in Tampa and Portsmouth, NH by 
          <a href="https://twitter.com/jwd2a">@jwd2a</a> and <a href="https://twitter.com/mgrocki">@mgrocki</a>.
        </p>
        <p>Feedback? Ideas? We'd love to hear them at <a href="mailto:feedback@reviewcycle.io">feedback@reviewcycle.io</a></p>
      </footer>
    </div>
  );
}

export default withRouter(Home);
