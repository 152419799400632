import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import contentEditable from '../Editable';
import { useAuth0 } from '../Auth';
import ProjectService from '../../services/project.service';

require('./styles.scss');
const EditableTitle = contentEditable('h4');

const NavBar = (props) => {
  const {
    project
  } = props;

  const { user } = useAuth0();

  const updateProjectTitle = (value) => {
    ProjectService.updateProject(project._id, { title: value }, user.sub);
  }

  return (
    <nav className="navbar d-flex align-items-center bg-primary">
      <div className="d-flex flex-column text-white">
        <div className="d-flex align-items-center">
          <EditableTitle
            value={project.title || 'Untitled'}
            onSave={updateProjectTitle}
            className='project-title p-0 m-0'
          />
        </div>
      </div> 
      <Link to="/dashboard">
        <button className="btn btn-outline-secondary">My Projects</button>
      </Link>
    </nav>
  );
}

export default withRouter(NavBar);
