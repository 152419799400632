import { get } from '../util/request';

class MessageService {
  getAssetMessages(assetId) {
    return new Promise((resolve, reject) => {
      get(`/assets/${assetId}/messages`)
        .then((messages) => resolve(messages.data))
        .catch(() => reject(new Error('There was an error getting the messages')))
    });
  }  
}

export default new MessageService();
