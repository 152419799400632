import React from 'react';
require('./styles.scss');

const lightbulb = require('../../img/lightbulb.png');
const rect = require('../../img/drawing-rectangle.png');


const NoteTools = (props) => {
  return (
    <div className="d-flex align-items-center p-3 note-tools">
      {!props.noteModeEnabled &&
        <button className="btn btn-outline-secondary" onClick={props.toggleNoteMode}>
          Add Note
        </button> 
      }
      {props.noteModeEnabled &&
        <div className="d-flex align-items-center">
          <button className="btn btn-primary" onClick={props.toggleNoteMode}>
            Cancel
          </button>
          <div className="tip ml-3">
            <img src={lightbulb} alt="lightbulb icon" />
            <span className="mx-2">
              Drag to create a rectangle and add a note
            </span>
            <img src={rect} alt="rectangle icon" />
          </div>
        </div>
      }
    </div>
  );
}

export default NoteTools;
