import React, { Fragment, useState} from 'react';
import moment from 'moment';
import {Dropdown} from 'react-bootstrap';
import {Tooltip} from 'react-tippy';
import {convertFromRaw} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import renderHTML from 'react-render-html';
import contentEditable from '../Editable';
import AssetService from '../../services/asset.service';

import 'react-tippy/dist/tippy.css';
require('./styles.scss');

const Versions = (props) => { 
  const {
    asset,
    selectedFile,
    onAddVersion,
    onSwitchVersion,
    onUpdate,
    onZoomImage,
  } = props; 

  const { files } = asset;

  const onSelectVersion = (UUID) => {
    onSwitchVersion(UUID);
  } 

  const [readCurrentNotes, setReadCurrentNotes] = useState(false);

  const onOpenVersionNotes = () => {
    localStorage.setItem(`vnotes-${files[selectedFile]._id}`, true);
    setReadCurrentNotes(true);
  }

  const updateAssetTitle = (title) => {
    AssetService.updateAsset(asset._id, { title })
      .then((asset) => {
        onUpdate(asset);
      })
      .catch((e) => console.log(e));
  } 

  let versionNotes;
  let tooltipHTML;

  if (!asset.files[selectedFile]) return null;

  if (files[selectedFile] && files[selectedFile].versionNotes) {
    versionNotes = stateToHTML(convertFromRaw(JSON.parse(files[selectedFile].versionNotes)));
    tooltipHTML = (
      <div className="p-4 text-left rounded">
        <div className="version-notes-popover-header border-bottom">
          <h6>What's changed in this version:</h6>
        </div>
        <div className="version-notes-popover-body pt-2">
          <Fragment>{renderHTML(versionNotes)}</Fragment>
        </div>
      </div>
    );
  }

  const EditableTitle = contentEditable('p');
    let versionTitle = 'Latest Version';
    if (selectedFile !== asset.files.length - 1) {
      versionTitle = `Version ${selectedFile + 1}`;
    }  

  const hasVersionNotes = files[selectedFile].versionNotes;

  const hasReadVersionNotes = readCurrentNotes || localStorage.getItem(`vnotes-${files[selectedFile]._id}`);

  return (    
    <div className="versions w-100 mb-3">
      <h6 className="mb-0">Asset Title</h6>
      <EditableTitle
        value={asset.title || asset.files[selectedFile].file.originalname}
        onSave={updateAssetTitle}
        placeholder="Enter a title for this asset"
        className="mr-3 asset-title"
      />
      <div className="d-flex align-items-center">
        {hasVersionNotes &&
          <div className="d-flex align-items-center">
            <i className="fal fa-file-alt mr-1" />
            <Tooltip
              html={tooltipHTML}
              trigger="click"
              arrow="true"
              interactive
              onShown={onOpenVersionNotes}
            >
              <button className="btn btn-link new-version">Version Notes</button>
              {!hasReadVersionNotes && 
                <div className="badge badge-danger ml-2">New</div>
              }
            </Tooltip>
          </div>
        }
        {!hasVersionNotes &&
          <div className="d-flex align-items-center no-version-notes">
            <i className="fal fa-file mr-1" />
            <span>No version notes</span>
          </div>
        }
        <div className="ml-auto text-as-link" onClick={onZoomImage}>
          <i className="fal fa-search-plus mr-2 ml-auto" />View Full Size
        </div>
        <div className="divider mx-3" />
        <Dropdown onSelect={(e) => onSelectVersion(e)}>
          <Dropdown.Toggle id="version-dropdown" as="span">
            {versionTitle}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {files.map((file, idx) => {
              return (
                <Dropdown.Item eventKey={file.UUID} key={file.UUID}>
                  {(idx === asset.files.length - 1) ? 'Latest Version' : `Version ${idx + 1}`}
                  {' '}
                  ({moment(file.createdAt).format('MMM Do, h:mmA')})
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        <div className="divider mx-3" />
        <button className="btn btn-link align-self-flex-end new-version text-as-link" onClick={onAddVersion}>
          Update Design
          <i className="fal fa-plus ml-2" />
        </button>
      </div>
    </div>
  );
}

export default Versions;
