import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from './components/Auth';
import history from './util/history';

const audience = 'https://dev.api.reviewcycle.io';
const domain = 'reviewcycle.auth0.com';
const clientId = '4jjLe4o120eyOKSapJwJbfoSdJKJTnIO';

const onRedirectCallback = appState => {
  history.replace(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
  <Auth0Provider
    domain={domain}
    client_id={clientId}
    redirect_uri={window.location.origin}
    audience={audience}
    onRedirectCallback={onRedirectCallback}
  >
    <App />
  </Auth0Provider>, 
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
