import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

require('./styles.scss');

class DashboardGridItem extends Component {
  render() {
    const { 
      project: {
        _id,
        assets,
        slug,
        createdAt,
        title,
      },
    } = this.props;
    
    let currentVersion;
    let previewImage;

    if (assets[0]) {
      currentVersion = assets[0].files[assets[0].files.length - 1];
      if (currentVersion) {
        previewImage = `https://s3.amazonaws.com/sketchdeck/${_id}/${assets[0].id}/${currentVersion.UUID}`;
      }
    }

    const gridItemImageStyles = {
      'backgroundImage': `url(${previewImage})`,
      'backgroundSize' : 'cover',
      'backroundRepeat': 'no-repeat',
    };

    return (
      <Link to={`/p/${slug}`} className="dashboard-grid-item-link">
        <div className="dashboard-grid-item border rounded d-inline-block mr-4 mb-4">
          <div 
            className="dashboard-grid-item-image d-flex align-items-center justify-content-center bg-light" 
            style={gridItemImageStyles}
          /> 
          <div className="dashboard-grid-item-content p-3 border-top">
            <div className="d-flex align-items-center">
              <h6 className="mr-auto">{title || 'Untitled'}</h6>
              <h6><small>Created {moment(createdAt).format('MMM Do, YYYY')}</small></h6>
            </div>
            <h6><small>{assets.length} assets</small></h6>
          </div>
        </div>
      </Link> 
    )
  }
}

export default DashboardGridItem;
