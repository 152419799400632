import React from 'react';
import moment from 'moment';

require('./styles.scss');

const NoteListItem = (props) => {
  const onClickNote = () => {
    props.openNote(props.note);
  }

  const onHoverNote = () => {
    props.onHoverNoteItem(props.note);
  }

  const {
    note: {
      body,
      createdAt,
      from,
      number,
      replyCount,
      resolved,
    }
  } = props;

  return (
    <div className="note-list-item chat-message p-4" onClick={onClickNote} onMouseEnter={onHoverNote}>
      <div className="d-flex align-items-center mb-2">
        <div className="note-number px-2">Note {number}</div>
        {resolved &&
            <div className="resolved ml-auto px-2"><i className="fal fa-check mr-1" />Resolved</div>
        }
      </div>
      <div className="d-flex align-items-center">
        <strong>{from.name}</strong>
        <span className="ml-2 chat-timestamp">{moment(createdAt).fromNow()}</span>
      </div>
      <p className="chat-message-body">{body}</p>
      <div className="d-flex justify-content-right mt-3 reply-count">
        <i className="fas fa-comment-alt-dots ml-auto mr-1" />
        {replyCount} {(replyCount === 1) ? 'reply' : 'replies'}
      </div>
    </div>
  );
}

export default NoteListItem;
